import { useAuth, useUserData } from 'hooks';
import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { RootStateProps } from 'types/root';

declare global {
  interface Window {
    $zoho:
      | {
          salesiq: {
            widgetcode: string;
            values: Record<string, any>;
            visitor: any;
            floatbutton: any;
            ready: any;
            afterReady: any;
            chatwindow: {
              visible: (action: 'show' | 'hide') => void;
              reload: () => void;
            };
          };
        }
      | any;
  }
}

interface ZohoSalesIQProps {}

const ZohoSalesIQ: React.FC<ZohoSalesIQProps> = (props) => {
  //  const zohoChat = useSelector((state: RootStateProps) => state.zohoChat);
  const { getUserData } = useUserData();
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    // if (window.$zoho) window.$zoho.salesiq.reset();
    // if (window.$zoho && window.$zoho?.salesiq && window.$zoho?.salesiq.chatwindow) {
    //   window.$zoho.salesiq.afterReady = function () {
    //     document.getElementById('zsiq_agtpic')?.click();
    //     if (getUserData('userData').first_name) {
    //       window.$zoho?.salesiq.visitor.name('Srinivas');
    //       window.$zoho?.salesiq.visitor.email('gsvasu25@gmail.com');
    //     }
    //   };
    //   window.$zoho.salesiq.afterReady();
    // } else {
    if (isLoggedIn) {
      window.$zoho = {};
      window.$zoho.salesiq = {
        widgetcode: process.env.REACT_APP_ZOHO_SALESIQ_CODE || '',
        values: {},
        visitor: {},
        floatbutton: function () {},
        ready: function () {},
        afterReady: function () {},
        chatwindow: {
          visible: (action: 'show' | 'hide') => {}
        }
      };

      // Create a new script element
      const d = document;
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.id = 'zsiqscript';
      s.defer = true;
      s.src = 'https://salesiq.zoho.com/widget';

      // Append the script element to the DOM
      const t = d.getElementsByTagName('script')[0];
      t.parentNode?.insertBefore(s, t);

      // Set up the widget when it's ready
      window.$zoho.salesiq.ready = function () {
        if (getUserData('userData').first_name) {
          window.$zoho.salesiq.visitor.name(getUserData('userData').first_name);
          window.$zoho.salesiq.visitor.email(getUserData('userData').email);
          window.$zoho.salesiq.floatwindow.visible('hide');
        }
      };
      window.$zoho.salesiq.afterReady = function () {
        window.$zoho.salesiq.floatwindow.visible('hide');
      };
    } else {
      if (window.$zoho && window.$zoho.salesiq) window.$zoho.salesiq.floatbutton.visible('hide');
    }
    //}
  }, [isLoggedIn]);

  return <React.Fragment>{<div id="zsiqwidget"></div>}</React.Fragment>;
};

export default ZohoSalesIQ;
