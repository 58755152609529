export const URLS = {
  USER: {
    ADD_USER: '/user/add_user',
    GET_USER: '/user/get_user',
    UPDATE_USER: '/user/update_profile',
    AWS_SETUP: '/user/aws_account_setup',
    AWS_SETUP_COMPLETE: '/user/aws_setup_setup',
    AWS_ACCOUNT_DETAILS: '/user/aws_account_details',
    REMOVE_AWS_ACCOUNT: '/user/aws_account_delete',
    CHECK_AWS_ACCOUNT_EXISTS: '/user/is_aws_account_exists',
    UPDATE_ROLE: '/user/update_role',
    ACCOUNT_STATUS: '/user/aws_account_status',
    SUBSCRIPTION_TYPE: '/user/subscription_type',
    WELCOME_MAIL: '/user/send_welcome_email',
    PW_RESET_STATUS: '/user/send_reset_status_email'
  },
  DASHBOARD: {
    GRAPH_DATA: '/landing_dashboard/get_graph_data',
    HEADER: '/landing_dashboard/dashboard'
  },
  COMMERCIAl: {
    FILTERS: '/menu/list_graph_filters',
    GRAPH_DATA: '/commercial/get_graph_data',
    HEADER: '/commercial/header',
    RDS_RECOMMENDATIONS: '/commercial/reserved_instances/rds_recommendations',
    OS_RECOMMENDATIONS: '/commercial/reserved_instances/opensearch_recommendations',
    ELASTICACHE_RECOMMENDATIONS: '/commercial/reserved_instances/elasticache_recommendations',
    RS_RECOMMENDATIONS: '/commercial/reserved_instances/redshift_recommendations',
    DASHBOARD: '/commercial/dashboard',
    RI_HEADER: '/commercial/reserved_instances/header',
    SP_HEADER: '/commercial/savings_plan/header',
    RI_GRAPH_DATA: '/commercial/reserved_instances/get_graph_data',
    SP_RECOMMENDATIONS: '/commercial/savings_plan/recommendations',
    SP_GRAPH_DATA: '/commercial/savings_plan/graph_data',
    SP_COVERAGE: '/commercial/savings_plan/coverage',
    SP_UTILIZATION: '/commercial/savings_plan/utilization',
    RI_COVERAGE: '/commercial/reserved_instances/coverage',
    RI_UTILIZATION: '/commercial/reserved_instances/utilization'
  },
  ARCHITECTURAL: {
    DASHBOARD: '/architectural/graph_data',

    HEADER: '/architectural/header',
    DT_HEADER: '/architectural/data_transfer/header',
    NGW_HEADER: '/architectural/nat_gateway/header',
    S3_OPT_HEADER: '/architectural/s3_optimization/header',

    AMD_GRAVITON_EBS_GRAPH_DATA: '/architectural/amd_graviton_ebs/graph_data',

    DT_GRAPH_DATA: '/architectural/graph_data/data_transfer',
    NGW_GRAPH_DATA: '/architectural/graph_data/nat_gateway',
    S3_OPT_GRAPH_DATA: '/architectural/graph_data/s3_optimization',

    DT_TYPE_GRAPH_DATA: '/architectural/data_transfer/cost_usage',
    NGW_TYPE_GRAPH_DATA: '/architectural/nat_gateway/cost_usage',
    S3_TYPE_GRAPH_DATA: '/architectural/s3_optimization/cost_usage',

    DT_RECOMMENDATIONS: '/architectural/data_transfer/recommendations',
    NGW_RECOMMENDATIONS: '/architectural/nat_gateway/recommendations',
    S3_OPT_RECOMMENDATIONS: '/architectural/s3_optimization/recommendations',
    GRAVITON_RECOMMENDATIONS: '/architectural_graviton/graviton_recommendations',
    AMD_RECOMMENDATIONS: '/architectural/amd_recommendations',
    EBS_RECOMMENDATIONS: '/architectural/ebs/ebs_recommendations'
  },
  OPERATIONAL: {
    DASHBOARD: '/ta_recom/dashboard',
    HEADER: '/ta_recom/header',

    GRAPH_DATA_BY_TYPE: '/ta_recom/graph_data_by_savings_type',
    TYPES_GRAPH_DATA: '/ta_recom/graph_data',

    EC2_RECOMMENDATIONS: '/ta_recom/low_utilized_ec2',
    EBS_VOL_RECOMMENDATIONS: '/ta_recom/under_utilized_ebs',
    ELB_RECOMMENDATIONS: '/ta_recom/idle_load_balancer'
  },
  CONTACT: {
    CREATE: '/support/',
    GET_DETAILS: '/support/get_contact_request_by_email'
  },
  RESERVED_SUBSCRIPTIONS: {
    FILTERS: '/subscriptions/list_filters',
    UTILIZATION: '/subscriptions/utilization',
    COVERAGE: '/subscriptions/coverage'
  },
  INQUIRY: {
    CREATE: '/inquiry/create',
    GET: '/inquiry/get'
  },
  SAP: {
    ADD: '/sap/customer_info/add',
    ACCOUNT_EXISTS: '/sap/customer_info/is_sap_account_exists',
    SAVE_STEP1: '/sap/solution_system_info/step1',
    SAVE_STEP2: '/sap/solution_system_info/step2',
    SAVE_STEP3: '/sap/solution_system_info/step3',
    SAVE_STEP4: '/sap/solution_system_info/step4',
    SAVE_STEP5: '/sap/solution_system_info/step5',
    GET_LATEST_RECORD: '/sap/solution_system_info/get_submitted_record',
    GET_IMAGES: '/sap/resources/get_images',
    UPLOAD_FILE: '/sap/resources/upload_excel_file',
    DOWNLOAD_FILE: '/sap/resources/get_excel_file',
    NEW_PLAN: '/sap/solution_system_info/new_plan',
    GET_PLANS: '/sap/solution_system_info/get'
  },
  INVITE: {
    SEND: '/invite/send_invite',
    SEND_EMAIL: '/invite/send_invite_email',
    GET: '/invite/get_invite',
    DELETE: '/invite/delete',
    ROLES_LIST: '/invite/list_roles'
  },
  MENU: {
    CONFIGURE: '/menu/savings_by_account'
  }
};
