import { useEffect, useState } from 'react';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';

// import { dispatch } from 'store';
// import { fetchDashboard } from 'store/reducers/menu';

// auth-provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import { useLocation } from 'react-router';
import { NOTIFY } from 'shared';
import { getPageDescription } from 'utils/getPageDescription';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DiagramSettingsProvider } from 'pages/traverse/Contexts/DiagramSettingsContext';
import { diagramSettingsReducer } from 'pages/traverse/Contexts/Reducers/DiagramSettingsReducer';
import { ResourceProvider } from 'pages/traverse/Contexts/ResourceContext';
import { resourceReducer } from 'pages/traverse/Contexts/Reducers/ResourceReducer';
import { NotificationProvider } from 'pages/traverse/Contexts/NotificationContext';
import ZohoSalesIQ from 'zoho';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        //refetchInterval: 60000,
        refetchOnWindowFocus: false,
        retry: 1
      }
    }
  });
  useEffect(() => {
    NOTIFY.ClearAll();
    const pageDescription = getPageDescription(location.pathname);
    document.title = pageDescription?.title;
  }, [location]);

  useEffect(() => {
    // dispatch(fetchDashboard()).then(() => {
    setLoading(true);
    // });
  }, []);

  if (!loading) return <Loader />;
  const initialDiagramSettingsState = {
    canvas: null,
    selectedResources: null,
    resources: []
  };
  const initialResourceState = {
    graphResources: [],
    resources: []
  };
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <ThemeCustomization>
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <DiagramSettingsProvider initialState={initialDiagramSettingsState} reducer={diagramSettingsReducer}>
                  <ResourceProvider initialState={initialResourceState} reducer={resourceReducer}>
                    <>
                      <Routes />
                      <ToastContainer position="bottom-right" newestOnTop />
                    </>
                  </ResourceProvider><ZohoSalesIQ />
                </DiagramSettingsProvider>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </ThemeCustomization>
      </NotificationProvider>
      
    </QueryClientProvider>
  );
};

export default App;
