import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import { openDrawer } from 'store/reducers/menu';
import { RootStateProps } from 'types/root';
import { LAYOUT_CONST } from 'types/config';
import { useUserData, useConfig } from 'hooks';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getSavingsName } from 'pages/utils/getSavingsData';
import { capitalizeFirstLetter } from 'utils/capitalizeEachWord';
import Footer from './Footer';
import Products from 'enums/products';
import { bgColorChangeMenu, SIDENAV_VISIBLE, FOOTER_HIDDEN_SCREENS } from 'constants/LayoutConstants';
import { SideNavConfig, SideNavVisible } from 'constants/types';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { getUserData } = useUserData();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);
  const { container, miniDrawer, menuOrientation } = useConfig();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { visibility } = useParams();
  const [showSideNav, setShowSideNav] = useState(false);
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  interface BreadCrumbItem {
    name: string;
    href: string;
  }
  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    const pathName: string = pathname.split('/')[1];
    let navVisible: SideNavConfig | undefined = undefined;
    if (Object.keys(SIDENAV_VISIBLE).includes(pathName)) {
      navVisible = SIDENAV_VISIBLE[pathName as keyof SideNavVisible];
    }
    if (navVisible && navVisible.showSideNav) {
      if (!navVisible.hiddenNavUrls.includes(pathname)) setShowSideNav(true);
      else setShowSideNav(false);
    } else setShowSideNav(false);
  }, [getUserData, pathname]);

  const formatBreadCrumbLabel = (value: any) => {
    const val = getSavingsName(value) || capitalizeFirstLetter(value);
    return val.replace(/-/g, ' ');
  };
  useEffect(() => {
    const pathComps = pathname.split('/');
    let pathArray: BreadCrumbItem[] = pathComps.map((item, index) => {
      let href = '';
      switch (item) {
        case Products.TRAVERSE.toLowerCase():
          href = '/traverse/welcome';
          break;
        case Products.TREKORA.toLowerCase():
          href = '/trekora/dashboard';
          break;
        default:
          href = pathComps.slice(0, index + 1).join('/');
          break;
      }
      return { name: item, href };
    });
    const lowerCasePathComps = pathComps.map((comp) => comp.toLowerCase());
    if (lowerCasePathComps.includes('diagrams')) {
      if (visibility !== undefined) {
        pathArray = pathArray.filter((i) => i.name !== visibility);
      }
    }
    const breadcrumbComps = pathArray.map((breadcrumb: BreadCrumbItem, index: number) => {
      return (
        <Link underline="hover" key={index + 2} color="inherit" href={`${breadcrumb.href}`} sx={{ color: '#00248C' }}>
          {formatBreadCrumbLabel(breadcrumb.name)}
        </Link>
      );
    });

    setBreadcrumbs([
      ...breadcrumbComps.splice(1, breadcrumbComps.length - 2),
      <Typography key="3" color="text.primary">
        {formatBreadCrumbLabel(pathArray[pathArray.length - 1].name)}
      </Typography>
    ]);
  }, [pathname]);

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Stack direction="row">
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        {showSideNav && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
        <Box
          component="main"
          sx={{
            width: 'calc(100% - 320px)',
            flexGrow: 1,
            backgroundColor: showSideNav || bgColorChangeMenu.includes(pathname.toLowerCase()) ? '#F5F5F5' : '#ffffff',
            p: { xs: 2, sm: 3 }
          }}
        >
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />

          <Container
            maxWidth={container ? 'xl' : false}
            sx={{
              ...(container && { px: { xs: 0, sm: 0 } }),
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Stack rowGap={2}>
              {showSideNav && (
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
              )}
              <Outlet />
            </Stack>
          </Container>
        </Box>
      </Stack>
      {!FOOTER_HIDDEN_SCREENS.includes(pathname.toLowerCase()) && <Footer />}
    </Box>
  );
};

export default MainLayout;
