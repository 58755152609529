// material-ui
import { useTheme } from '@mui/material/styles';
import { ButtonBase, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
// import Logo from 'components/logo';
import { useConfig, useEnvironment, useSelectedProduct, useUserData } from 'hooks';
import './switcher.css';
// types
import { LAYOUT_CONST } from 'types/config';
import { useNavigate } from 'react-router-dom';
import Products from 'enums/products';
import EnvironmentMode from 'enums/environmentMode';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const { currentEnvironment } = useEnvironment();

  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { addUserData } = useUserData();
  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isTrekora, isTraverse } = useSelectedProduct();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  useEffect(() => {
    const body = document.querySelector('body');
    body?.addEventListener('click', () => {
      setIsOpen(false);
    });
  }, []);
  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : '60px',
        width: isHorizontal ? { xs: '100%', lg: '424px' } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
    >
      <div className="d-flex align-items-center">
        <div className="menu-container" ref={menuRef}>
          {/* <img src="/switcher.svg" alt="switcher" style={{ marginRight: '8px' }} onClick={toggleMenu} className="cursor-pointer" /> */}

          <button onClick={toggleMenu} className="switcher-btn">
            <img src="/switcher.svg" alt="switcher" style={{ marginRight: '8px', top: '4px', position: 'relative' }} />
          </button>
          {isOpen && (
            <div className="menu-content">
              <div className="pointer"></div>
              <div className="menu-heading text-center">Switch to other products</div>
              <ul className="menu-list">
                <li
                  className="d-flex align-items-center cursor-pointer switcher-menu-item"
                  onClick={() => {
                    setIsOpen(false);
                    addUserData('selectedType', Products.TREKORA);
                    addUserData('selectedAwsAccountId', '');
                    navigate('/trekora/savingsReview');
                  }}
                >
                  <img src="/trekora-switcher.svg" alt="Trekora" /> <span className="prl-2">Trekora</span>
                </li>
                {currentEnvironment === EnvironmentMode.DEVELOPMENT && (
                  <li
                    className="d-flex align-items-center cursor-pointer switcher-menu-item"
                    onClick={() => {
                      setIsOpen(false);
                      addUserData('selectedType', Products.TRAVERSE);
                      addUserData('selectedAwsAccountId', '');
                      navigate('/traverse/welcome');
                    }}
                  >
                    <img src="/traverse-switcher.svg" alt="Traverse" /> <span className="prl-2">Traverse</span>
                  </li>
                )}
                <li
                  className="d-flex align-items-center cursor-pointer switcher-menu-item"
                  onClick={() => {
                    setIsOpen(false);
                    addUserData('selectedType', Products.SAPASSIST);
                    addUserData('selectedAwsAccountId', '');
                    navigate('/sapAssist/dashboard');
                  }}
                >
                  <img src="/SAPAssist-switcher.svg" alt="SAPAssist" /> <span className="prl-2">SAPAssist</span>
                </li>
                {(currentEnvironment === EnvironmentMode.DEVELOPMENT || currentEnvironment === EnvironmentMode.DEMO) && (
                  <li
                    className="d-flex align-items-center  cursor-pointer switcher-menu-it"
                    onClick={() => {
                      setIsOpen(false);
                      addUserData('selectedType', Products.INFORA);
                      addUserData('selectedAwsAccountId', '');
                      navigate('/Infora/cases');
                    }}
                  >
                    <img src="/icons/infora.svg" alt="infora" /> <span className="prl-2">Infora</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {isTrekora && (
          <ButtonBase
            disableRipple
            onClick={() => {
              addUserData('selectedAwsAccountId', '');
              navigate('/trekora/savingsReview');
            }}
          >
            <img src="/Trekora-logo.svg" alt="Trekora" className="prl-2" />
          </ButtonBase>
        )}
        {isTraverse && (
          <ButtonBase disableRipple onClick={() => navigate('/traverse/welcome')}>
            <img src="/Traverse-logo.svg" alt="Traverse" className="prl-2" />
          </ButtonBase>
        )}
      </div>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
