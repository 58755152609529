// material-ui
import { Theme } from '@mui/material/styles';
import { Box, ListItemButton, ListItemText, Stack, useMediaQuery, Divider } from '@mui/material';

// project import
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import { LAYOUT_CONST } from 'types/config';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useUserData, useConfig } from 'hooks';
import { SubscriptionPlan } from './SubsciptionPlan';

const CustomDivider = () => {
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{
        height: '16px',
        display: 'flex',
        alignItems: 'center',
        borderColor: '#8C8C8C',
        alignSelf: 'auto'
      }}
    />
  );
};

const headerItems = [
  {
    label: 'Need Help?',
    key: '/needHelp'
  },
  // {
  //   label: 'FAQ',
  //   key: '/faq'
  // },
  {
    label: 'Home',
    key: '/home'
  }
];

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const { getUserData } = useUserData();
  const [current, setCurrent] = useState('/dashboard');
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const selectedAccountId = getUserData('selectedAwsAccountId');
  const isAwsSetupDone = selectedAccountId !== '' ? true : false;

  useEffect(() => {
    setCurrent(location.pathname || '/dashboard');
  }, [location.pathname]);

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && isAwsSetupDone && <DrawerHeader open={true} />}
      {!downLG && <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}></Box>}

      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <div className="d-none d-md-flex">
        <SubscriptionPlan />
      </div>
      <Stack direction="row" alignItems="center" divider={<CustomDivider />} columnGap={2} sx={{ ml: 2 }}>
        <Stack className="d-none d-md-flex" direction="row" divider={<CustomDivider />} alignItems="center" columnGap={2}>
          {headerItems.map((item, index) => (
            <ListItemButton
              key={item.key}
              selected={current === item.key}
              onClick={() => {
                if (item.key === '/needHelp') {
                  // window.$zoho.salesiq.floatwindow.visible('show');
                  navigate(item.key);
                } else {
                  setCurrent(item.key);
                  navigate(item.key);
                }
              }}
              sx={{
                height: '60px',
                color: '#00248C',
                '&.Mui-selected': {
                  color: 'white',
                  background: 'linear-gradient(180deg, #9027E2 0%, #00248C 100%)'
                }
              }}
            >
              <ListItemText primary={<span style={{ whiteSpace: 'nowrap' }}>{item.label}</span>} />
            </ListItemButton>
          ))}
        </Stack>
        <Notification />
        {!downLG && <Profile />}
        {downLG && <MobileSection />}
      </Stack>
    </>
  );
};

export default HeaderContent;
