// project import
import { commercialSavings, architecturalSavings, operationalSavings, dashboard } from './applications';
import { explore, configure, diagrams } from './traverseMenuItems';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[]; traverseItems: NavItemType[] } = {
  items: [dashboard, commercialSavings, architecturalSavings, operationalSavings],
  traverseItems: [explore, diagrams, configure]
};

export default menuItems;
