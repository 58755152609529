import { SideNavVisible } from './types';

export const SIDENAV_VISIBLE: SideNavVisible = {
  trekora: { showSideNav: true, hiddenNavUrls: ['/trekora/savingsReview', '/trekora/awsAccountSetup'] },
  traverse: { showSideNav: true, hiddenNavUrls: [] },
  sapasisst: { showSideNav: false, hiddenNavUrls: [] }
};
export const bgColorChangeMenu: string[] = ['/trekora/awsaccountsetup', '/faq', '/limited-access', '/managed-services'];

export const FOOTER_HIDDEN_SCREENS: string[] = ['/home'];
