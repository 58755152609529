import { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { Box, Stack, Typography } from '@mui/material';
import { useConfig } from 'hooks';
import { formatAxisLabels, roundToNearest } from 'utils/utilityFns';
import { formatValue } from 'utils/formatter';

interface LineGraphProps {
  name: string;
  data: any;
  categories: string[];
  formater?: 'currency' | 'percentage' | 'number';
  showTitle?: boolean;
}

const LineGraph: React.FC<LineGraphProps> = ({ name, data, categories, formater = 'currency', showTitle = true }) => {
  const { mode } = useConfig();
  const [series, setSeries] = useState([{ name: name || 'Costs', data }]);

  useEffect(() => {
    if (data) {
      if (!showTitle) setSeries(data);
      else setSeries([{ name: name || 'Costs', data }]);
    } else {
      setSeries([]);
    }
  }, [data, categories]);

  const formatData = (value: any) => {
    return formatValue(formater, value);
  };
  const flattenedData: number[] = series.reduce((acc, obj) => {
    return acc.concat(obj.data.map(Number));
  }, []);
  const allZeros = data.every((val: number | string) => {
    const num = typeof val === 'string' ? parseFloat(val) : val;
    return num === 0;
  });

  const options: ChartProps = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: { enabled: false }
    },

    colors: ['#00248C'],
    markers: {
      size: [4, 7],
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: -5,
      offsetY: -6,
      formatter: (value: number) => {
        let val = Math.abs(value);
        return formatValue(formater, val);
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },
    xaxis: {
      categories,
      title: {
        text: 'Timeline',
        style: {
          fontWeight: 600
        }
      },
      labels: {
        style: {
          colors: '#000000'
        }
      }
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max: roundToNearest(Math.max(...data)),
      decimalsInFloat: 0,
      labels: {
        formatter: formatAxisLabels,
        style: {
          colors: '#000000'
        }
      }
    },
    tooltip: {
      enabled: false,
      y: {
        formatter(value: number) {
          return formatData(value);
        }
      },
      theme: mode === 'dark' ? 'dark' : 'light'
    },
    legend: {
      show: false,
      showForSingleSeries: false,
      customLegendItems: [name]
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: {
            show: true
          }
        }
      }
    ]
  };
  var lineoptions: ChartProps = {
    chart: {
      toolbar: {
        show: false
      },
      zoom: { enabled: false },
      type: 'line'
    },
    colors: ['#00248C', '#AF57F3', '#1792F3', '#66E5FB', '#FF9800'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },

    markers: {
      size: [4, 7],
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Month',
        offsetY: -15,
        style: {
          fontWeight: 600
        }
      }
    },
    yaxis: {
      title: {
        text: 'Estimated cost ($)'
      },
      tickAmount: 4,
      min: 0,
      max: roundToNearest(Math.max(...flattenedData)),
      logarithmic: false
    },
    legend: {
      show: true,
      offsetY: 5,
      position: 'bottom',
      horizontalAlign: 'center',
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    }
  };
  if (allZeros && options && data.length > 0) {
    options.annotations = {
      points: [
        {
          x: categories[Math.round(categories.length / 2) - 1],
          y: 2000,
          yAxisIndex: 0,
          seriesIndex: 0,
          marker: {
            size: 0,
            fillColor: 'transparent'
          },
          label: {
            borderColor: 'transparent',
            text: 'No Data Available',
            textAnchor: 'front',
            style: {
              color: '#000000',
              fontSize: '18px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    };
    options.yaxis = {
      ...options.yaxis,
      show: true,
      min: 0,
      max: 4000,
      tickAmount: 4
    };
    options.dataLabels = {
      enabled: false
    };
    options.tooltip = {
      enabled: false
    };
    options.stroke = {
      width: 0
    };
    options.markers = {
      size: 0
    };
  }
  return (
    <Box sx={{ p: 2.5, pb: 0 }}>
      {showTitle && (
        <>
          <Stack sx={{ mb: -1 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: '600', fill: 'rgb(55, 61, 63)' }}>{name || 'Costs ($)'}</Typography>
          </Stack>

          {allZeros && <ReactApexChart options={options} series={series} type="line" />}
          {!allZeros && <ReactApexChart options={options} series={series} type="line" />}
        </>
      )}
      {!showTitle && <ReactApexChart width="95%" options={lineoptions} series={series} height={300} type="line" />}
    </Box>
  );
};

export default LineGraph;
