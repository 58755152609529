import Products from 'enums/products';
import useUserData from './useUserData';

export const useSelectedProduct = () => {
  const { getUserData } = useUserData();

  const isTrekora = getUserData('selectedType') === Products.TREKORA;
  const isTraverse = getUserData('selectedType') === Products.TRAVERSE;
  const isSAP = getUserData('selectedType') === Products.SAPASSIST;
  const isInfora = getUserData('selectedType') === Products.INFORA;

  return { isTrekora, isTraverse, isSAP, isInfora };
};
